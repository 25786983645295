<template>
  <div>
    <b-card>
      <progress-bar-status :progress="2" />
    </b-card>
    <template>
      <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0 d-flex column mt-3">
        <b-col
          cols="12"
          class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden mb-10"
        >
          <h2>Diisi oleh HB</h2>
          <b-form>
            <b-form-group
              label="HB Meter:"
              :state="
                v$.aktivasiHB.hb_meter.$dirty
                  ? !v$.aktivasiHB.hb_meter.$error
                  : null
              "
            >
              <b-form-input
                id="input-1"
                v-model="aktivasiHB.hb_meter"
                type="number"
                class="mb-3"
                placeholder="Masukkan Tekanan Darah"
                :state="
                  v$.aktivasiHB.hb_meter.$dirty
                    ? !v$.aktivasiHB.hb_meter.$error
                    : null
                "
                @blur="v$.aktivasiHB.hb_meter.$touch()"
                required
              />
              <b-form-invalid-feedback
                v-if="
                  v$.aktivasiHB.hb_meter.$dirty && v$.aktivasiHB.hb_meter.$error
                "
              >
                data harus diisi
              </b-form-invalid-feedback>
            </b-form-group>

            <b-row>
              <b-col cols="3">Metode Kupper Sulfat:</b-col>

              <b-col cols="4">
                <label>Berat Jenis 1.053</label>
                <b-form-radio-group
                  v-model="aktivasiHB.kupper_sulfat"
                  :state="
                    v$.aktivasiHB.kupper_sulfat.$dirty
                      ? !v$.aktivasiHB.kupper_sulfat.$error
                      : null
                  "
                  @blur="v$.aktivasiHB.kupper_sulfat.$touch()"
                >
                  <b-form-radio value="> 12,5 gr% Tenggelam"
                    >> 12.5 gr %
                    <span class="pl-3">Tenggelam</span></b-form-radio
                  >
                  <b-form-radio value="= 12,5 gr% Melayang"
                    >= 12.5 gr %
                    <span class="pl-3">Melayang</span></b-form-radio
                  >
                  <b-form-radio value="< 12,5 gr% Mengapung"
                    >< 12.5 gr %
                    <span class="pl-3">Mengapung</span></b-form-radio
                  >
                </b-form-radio-group>
              </b-col>

              <b-col cols="4">
                <label>Berat Jenis 1.062</label>
                <b-form-radio-group
                  v-model="aktivasiHB.kupper_sulfat"
                  :state="
                    v$.aktivasiHB.kupper_sulfat.$dirty
                      ? !v$.aktivasiHB.kupper_sulfat.$error
                      : null
                  "
                  @blur="v$.aktivasiHB.kupper_sulfat.$touch()"
                >
                  <b-form-radio value="> 17 gr% Tenggelam"
                    >< 17 gr % <span class="pl-3">Mengapung</span></b-form-radio
                  >
                  <b-form-radio value="= 17 gr% Melayang"
                    >= 17 gr % <span class="pl-3">Melayang</span></b-form-radio
                  >
                  <b-form-radio value="< 17 gr% Mengapung"
                    >> 17 gr % <span class="pl-3">Tenggelam</span></b-form-radio
                  >
                </b-form-radio-group>
              </b-col>
            </b-row>

            <b-form-invalid-feedback
              v-if="
                v$.aktivasiHB.kupper_sulfat.$dirty &&
                v$.aktivasiHB.kupper_sulfat.$error
              "
            >
              data harus diisi
            </b-form-invalid-feedback>

            <b-form-group
              label="Golongan Darah:"
              :state="
                v$.aktivasiHB.blood_id.$dirty
                  ? !v$.aktivasiHB.blood_id.$error
                  : null
              "
            >
              <b-form-select
                class="mb-3"
                v-model="aktivasiHB.blood_id"
                :options="options"
                :state="
                  v$.aktivasiHB.blood_id.$dirty
                    ? !v$.aktivasiHB.blood_id.$error
                    : null
                "
                @blur="v$.aktivasiHB.blood_id.$touch()"
                required
              />
              <b-form-invalid-feedback
                v-if="
                  v$.aktivasiHB.blood_id.$dirty && v$.aktivasiHB.blood_id.$error
                "
              >
                data harus diisi
              </b-form-invalid-feedback>
            </b-form-group>

            <b-col
              cols="12"
              class="d-flex justify-content-end overflow-hidden mb-10"
            >
              <b-button
                variant="danger"
                class="mx-3"
                size="md"
                :disabled="acceptOrRejectionSuccess"
                @click="openConfirmationModal('reject')"
              >
                Rejected
              </b-button>
              <b-button
                variant="primary"
                class="mx-3"
                size="md"
                :disabled="acceptOrRejectionSuccess"
                @click="openConfirmationModal('accept')"
              >
                Terima
              </b-button>
            </b-col>
          </b-form>
        </b-col>
      </b-row>
      <b-modal id="modal" v-model="showModal" centered hide-header hide-footer>
        <template>
          <h5 v-if="modalType === 'accept'" class="mb-0">
            Konfirmasi Approved Status
          </h5>
          <h5 v-else-if="modalType == 'reject'" class="mb-0">
            Konfirmasi Rejected Status
          </h5>
        </template>

        <p v-if="modalType === 'accept'">
          Apakah anda akan menyetujui Pendonor Atas Nama
          <strong>{{ currentDataPendonor.name }}</strong
          >?
        </p>
        <div v-else-if="modalType == 'reject'">
          <span>Apakah anda akan menolak Pendonor Atas Nama </span>
          <strong>{{ currentDataPendonor.name }}</strong
          >?
          <div class="mt-3">
            <label>Alasan:</label>
            <b-form-select
              v-model="aktivasiHB.rejectionReason"
              :options="rejectionReasons"
              @change="handleChangeRejectionReason"
            ></b-form-select>
            <div
              class="border-1 mt-3"
              v-if="aktivasiHB.rejectionReason === 'lain-lain'"
            >
              <b-form>
                <label for="textarea">Catatan</label>
                <b-form-textarea
                  id="textarea-no-resize"
                  v-model="aktivasiHB.note"
                  placeholder="Masukkan Catatan"
                  rows="3"
                  no-resize
                ></b-form-textarea>
              </b-form>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end flex-wrap mt-32">
          <b-button variant="text" @click="showModal = false"> Tutup </b-button>
          <b-button
            class="ml-16"
            :disabled="isLoading"
            :variant="modalType === 'accept' ? 'primary' : 'danger'"
            @click="handleAction(modalType)"
          >
            {{
              modalType === "accept"
                ? "Ya, Terima"
                : modalType === "reject"
                ? "Ya, Reject"
                : "Ya, Edit"
            }}

            <b-spinner small label="Loading" v-if="isLoading"></b-spinner>
          </b-button>
        </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BIcon,
  BBadge,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BTable,
  BPagination,
  BSpinner,
  BModal,
  BFormSelect,
  BCard,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormTextarea,
} from "bootstrap-vue";
import ProgressBarStatus from "../../components/common/ProgressBarStatus.vue";
import AktivasiPendonorService from "../../../../../../api/managependonor/managePendonorAPI";
import manageDoctorAPI from "../../../../../../api/managedoctor/manageDoctorAPI";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "ViewHBStatus",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    BCol,
    BRow,
    BIcon,
    BBadge,
    BButton,
    BForm,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BTable,
    BPagination,
    BSpinner,
    BModal,
    BFormSelect,
    BFormTextarea,
    BCard,
    BFormRadioGroup,
    BFormInvalidFeedback,
    ProgressBarStatus,
  },
  data() {
    return {
      labs: ["Registrasi", "Dokter", "HB", "Aftaf"],
      registration_id: null,
      currentDataPendonor: null,
      acceptOrRejectionSuccess: false,
      routerPathname: "",
      showModal: false,
      modalType: "",
      isLoading: false,
      isBusy: false,
      options: [
        { value: null, text: "Pilih Golongan Darah" },
        { value: 1, text: "A+" },
        { value: 2, text: "A-" },
        { value: 3, text: "B+" },
        { value: 4, text: "B-" },
        { value: 5, text: "AB+" },
        { value: 6, text: "AB-" },
        { value: 7, text: "O" },
      ],
      aktivasiHB: {
        hb_meter: "",
        kupper_sulfat: "",
        blood_id: null,
        rejectionReason: null,
        doctor_examinations_id: this.$route.params.id,
        status: { Approved: "accept" },
      },
      rejectionReasons: [
        { value: null, text: "Pilih" },
        { value: "hb rendah", text: "HB Rendah" },
        { value: "hb tinggi", text: "HB Tinggi" },
        { value: "lain-lain", text: "Lain-lain" },
      ],
    };
  },
  validations() {
    return {
      aktivasiHB: {
        hb_meter: { required },
        kupper_sulfat: { required },
        blood_id: { required },
      },
    };
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  created() {
    this.getDoctorExamination(this.$route.params.id);
    this.routerPathname = this.$route.matched[0].path;
  },
  methods: {
    async getDoctorExamination(id) {
      const response = await manageDoctorAPI.getDetail(id);
      this.registration_id = response.data.data.registration_id;
      await this.getPendonor(this.registration_id);
    },
    async getPendonor(id) {
      const response = await AktivasiPendonorService.getPendonor(id);
      this.currentDataPendonor = response.data.data[0];
    },
    async storeHB(status) {
      const {
        aktivasiHB,
        currentDataPendonor,
        isApproved,
        $store,
        $router,
        $bvToast,
      } = this;
      const formData = new FormData();
      formData.append("hb_meter", aktivasiHB.hb_meter);
      formData.append(
        "doctor_examinations_id",
        aktivasiHB.doctor_examinations_id
      );
      formData.append("registration_id", currentDataPendonor.registration_id);
      formData.append("kupper_sulfat", aktivasiHB.kupper_sulfat);
      formData.append("blood_id", aktivasiHB.blood_id);
      formData.append("reason_rejected", aktivasiHB.rejectionReason);
      formData.append("note", aktivasiHB.note);
      formData.append("status", status === "accept" ? "Approved" : "Rejected");
      await $store.dispatch("manageActivasiHB/storeHB", formData);
      $bvToast.toast("Data Berhasil Ditambahkan", {
        title: "Success",
        variant: "success",
        solid: true,
      });
      console.log("Data successfully added");
      $router.push({ name: "activasiHB" });
    },
    getProgressBarVariant() {
      return this.currentDataPendonor.registration_status === "Approved"
        ? "danger"
        : "";
    },
    openConfirmationModal(type) {
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.$bvToast.toast("Formulir tidak valid. Harap periksa kembali.", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.modalType = type;
      this.showModal = true;
    },
    handleAction(status) {
      this.isLoading = true;
      this.storeHB(status);
      this.showModal = false;
    },
  },
};
</script>
